<template>
  <ClientOnly>
    <FimButton
      :type="isButton ? 'secondary' : 'raw'"
      data-testid="pdp_button_favorite"
      no-padding
      class="!gap-0"
      :class="[
        {
          'p-4': isButton,
          'pointer-events-none opacity-30': isUpdating,
        },
      ]"
      @click="toggleWishlistItem"
    >
      <div class="sr-only">
        {{
          isInWishlist
            ? $t('basket_card.remove_from_wishlist_label')
            : $t('basket_card.add_to_wishlist_label')
        }}
      </div>
      <template #icon>
        <LazyIconFielmannFavoriteInactive
          v-if="!isInWishlist"
          data-testid="list_button_favorite_inactive"
          class="size-6"
        />
        <LazyIconFielmannClose
          v-else-if="isInWishlist && showCloseIcon"
          data-testid="list_button_favorite_active"
          class="size-6"
        />
        <LazyIconFielmannFavoriteActive
          v-else
          data-testid="list_button_favorite_active"
          class="size-6"
        />
      </template>
    </FimButton>
    <template #fallback>
      <LazyIconFielmannFavoriteInactive
        data-testid="list_button_favorite_inactive"
        class="size-6"
      />
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { Product, Variant } from '@scayle/storefront-nuxt'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  variant: {
    type: Object as PropType<Variant>,
    default: undefined,
    required: false,
  },
  showCloseIcon: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: String,
    default: 'default',
    validator: (val: string) => ['default', 'button'].includes(val),
  },
})

const isButton = props.layout === 'button'

const { isUpdating, isInWishlist, toggleWishlistItem } = await useFimWishlist({
  product: props.product,
  variant: props.variant,
})
</script>
